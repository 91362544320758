import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppBar from '../AppBar'
import { SnackbarProvider } from 'notistack'

class Layout extends Component {
  render () {
    const {children} = this.props
    return (
      <div className="App ">
        <AppBar />
        <SnackbarProvider>
        {children}
        </SnackbarProvider>
      </div>
    )
  }
}

// map state to props
const mapStateToProps = ({home}) => {
  const {message} = home
  return {message}
}

export default withRouter(connect(mapStateToProps)(Layout))
