import {
  CHANGE_MESSAGE_WELCOME,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  SAVE_CONTACT,
  SAVE_CONTACT_FAILURE,
  SAVE_CONTACT_SUCCESS,
  SAVE_SUSCRIBER,
  SAVE_SUSCRIBER_SUCCESS,
  SAVE_SUSCRIBER_FAILURE
} from '../actions/types'

const INIT_STATE = {
  message: 'Welcome',
  users: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_USERS:
      return {...state, message: 'Getting users...', users: {}}

    case GET_USERS_SUCCESS:
      return {...state, message: 'Ready users', users: action.payload}

    case GET_USERS_FAILURE:
      return {...state, message: 'Error at getting users', users: {}}

    case CHANGE_MESSAGE_WELCOME:
      return {...state, message: action.payload}

    case SAVE_CONTACT:
      return {...state,}

    case SAVE_CONTACT_SUCCESS:
      return {...state,}

    case SAVE_CONTACT_FAILURE:
      return {...state,}
    case SAVE_SUSCRIBER:
      return {...state,}

    case SAVE_SUSCRIBER_SUCCESS:
      return {...state,}

    case SAVE_SUSCRIBER_FAILURE:
      return {...state,}
    default:
      return {...state}
  }
}
