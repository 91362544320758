import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../../img/logoTeon.svg";
import asterisco from "../../img/asterisco.svg";
import arbol from "../../img/arbol.svg";
import Snowfall from "react-snowfall";
import { Divider, Hidden } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Grow from "@material-ui/core/Grow";
import "./styles.css";
import styles from "./styles";
import { SentimentSatisfied } from "@material-ui/icons";

const Christmas = (props) => {
  const { name, languaje } = useParams();
  console.log('languaje:', languaje,useParams())
  const classes = styles();

  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    setTimeout(function() {
      setChecked(true);
    }, 2000);
  }, []);

  return (
    <div className={`root`}>
      <Hidden xsDown>
        <Snowfall color="white" snowflakeCount={200} />
      </Hidden>
      <Hidden smUp>
        <Snowfall color="white" snowflakeCount={40} />
      </Hidden>

      <div className={classes.headerContainer}>
        <div className={classes.divHeader1}>
          {" "}
          <img style={{ width: "100%" }} src={logo} alt="Logo TEON" />
        </div>
        <div className={classes.divHeader2}>
          <img className={classes.imgStar} src={asterisco} alt="Estrella" />
        </div>
      </div>
      <div className={"dateContainer"}>
        <p className="dateText">Diciembre 2020</p>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={`containerSecond`} direction="row">
            <Grid sm={6} className={classes.containerItem} item>
              <img className={classes.treeItem} src={arbol} alt="Arbol TEON" />
            </Grid>
            <Grid sm={5} className="containerText" item>
              <Grow in={true} timeout={2000}>
                <div className={classes.mainMsj}>
                  <p className={classes.contentStyle}>
                    {/* Te deseamos una Feliz Navidad y un próspero Año Nuevo */}
                    {languaje ? ' You are the reason we celebrate.' : ' Eres la razón por la que celebramos.'}
                    {/* Eres la razón por la que celebramos. */}
                    <br></br>
                    <br></br>
                    {languaje ? ' Happy Holidays!' : '  Feliz Navidad!'}
                    {/* Feliz Navidad! */}
                  </p>

                  <Divider className={`dividerStyle`} />
                </div>
              </Grow>
              <Grow in={checked} timeout={2000}>
                <h2 color="initial" className="titleStyle customer">
                  {name ? name : "Anónimo"}
                </h2>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Christmas;
