import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import MainPage from '../components/Page1'
import Layout from '../components/Layout'

class AppContainer extends Component {
  render () {
    return (
      <Layout>
        <Route path={`/`} exact component={MainPage}/>
        {/*<InitialPath*/}
          {/*path={`${match.url}`}*/}
          {/*component={Routes}*/}
        {/*/>*/}
      </Layout>
    )
  }
}

export default AppContainer
