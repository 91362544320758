export const CHANGE_MESSAGE_WELCOME = 'CHANGE_MESSAGE_WELCOME'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const SAVE_CONTACT = 'SAVE_CONTACT'
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS'
export const SAVE_CONTACT_FAILURE = 'SAVE_CONTACT_FAILURE'

export const SAVE_SUSCRIBER = 'SAVE_SUSCRIBER'
export const SAVE_SUSCRIBER_SUCCESS = 'SAVE_SUSCRIBER_SUCCESS'
export const SAVE_SUSCRIBER_FAILURE = 'SAVE_SUSCRIBER_FAILURE'
