import {
  CHANGE_MESSAGE_WELCOME,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  SAVE_CONTACT,
  SAVE_CONTACT_FAILURE,
  SAVE_CONTACT_SUCCESS,
  SAVE_SUSCRIBER,
  SAVE_SUSCRIBER_FAILURE,
  SAVE_SUSCRIBER_SUCCESS
} from './types'

export const changeMessage = (message) => ({
  type: CHANGE_MESSAGE_WELCOME,
  payload: message
})
export const getUsers = () => ({
  type: GET_USERS,
})
export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: data
})
export const getUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  payload: error
})

export const saveContact = (data, resetFunction, notifyFunction) => ({
  type: SAVE_CONTACT,
  payload: {data, resetFunction, notifyFunction}
})
export const saveContactSuccess = (data) => ({
  type: SAVE_CONTACT_SUCCESS,
  payload: data
})
export const saveContactFailure = (error) => ({
  type: SAVE_CONTACT_FAILURE,
  payload: error
})
export const saveSuscriber =(data,resetFunction,notifyFunction)=>({
  type:SAVE_SUSCRIBER,
  payload: {data,resetFunction,notifyFunction}
})
export const saveSuscriberSuccess = (data) => ({
  type: SAVE_SUSCRIBER_SUCCESS,
  payload: data
})
export const saveSuscriberFailure = (error) => ({
  type: SAVE_SUSCRIBER_FAILURE,
  payload: error
})
