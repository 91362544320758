import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import iconSet from '../../constants/selection.json'
import IcomoonReact from 'icomoon-react'


const styles = theme => {
  return ({
      tittleSeccion2: {
        paddingTop: '13%',
        paddingBottom: '10%',
        '&:hover div hr': {
          // ' h3':{
          width: '30%'
          // }
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: '18%',
        },
      },
      tittleNuestro: {
        marginRight: '10px',
        fontWeight: '700',
      },
      tittleEnfoque: {
        fontWeight: '700',
        color: '#E30513'
      },
      dividerTeon: {
        width: '10%',
        borderTop: '4px solid #E30513',
        marginTop: '2rem',
        marginBottom: '2rem',
        transition: 'width 1s',

      },
      descrpitionS2: {

        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: '0 14% 0 14%',
      },
      descriptionPS2: {
        fontFamily: 'Lato,sans-serif,Arial,Helvetica',
        fontSize: '21px',
        fontWeight: '300',
        fontStyle: 'normal',
        color: '#919191'
      },
      rootServices: {
        flexGrow: 1,
      },
      marginService: {
        margin: '2rem',
      },
      iconResposinve: {
        fill: 'red'
      },
      itemService: {
        padding: '50px',
        '&:hover': {
          backgroundColor: '#e30513',
        },
        '&:hover .hoverWhite': {
          color: 'white',
        },
        '&:hover svg path': {
          fill: 'white !important',
        },
        '&:hover div ': {
          color: 'white'
        },

      },
      itemServiceRed: {
        backgroundColor: '#6F6F6E',
        color: 'white',
        padding: '50px',
        '&:hover': {
          backgroundColor: '#e30513',
        }
      },
      itemTitleService: {
        color: '#e30513',
        fontWeight: '600',
      },
      itemTitleServiceWhite: {
        color: 'white',
        fontWeight: '600',
      },
      order1: {
        [theme.breakpoints.down('xs')]: {
          order: 1
        },
      },
      order2: {
        [theme.breakpoints.down('xs')]: {
          order: 2
        },
      },
      order3: {
        [theme.breakpoints.down('xs')]: {
          order: 4
        },
      },
      order4: {
        [theme.breakpoints.down('xs')]: {
          order: 3
        },
      },
      order5: {
        [theme.breakpoints.down('xs')]: {
          order: 5
        },
      },
      order6: {
        [theme.breakpoints.down('xs')]: {
          order: 6
        },
      },
    }
  )
}

class UsSection extends Component{
  render(){
    const {classes} = this.props
    return (
      <div id='nosotros'>
        <div className={classes.tittleSeccion2}>
          <h3>
              <span className={classes.tittleNuestro}>
                NUESTRO
              </span>
            <span className={classes.tittleEnfoque}>
               ENFOQUE
            </span></h3>
          <div>
            <hr className={classes.dividerTeon}/>
          </div>
          <div className={classes.descrpitionS2}>
            <p className={classes.descriptionPS2}>
              Proporcionamos servicios tecnológicos modernos que mejoran
              la experiencia de tus clientes aumentando
              la competitividad de tu empresa y garantizando
              total satisfacción de tus servicios.
            </p>
          </div>

        </div>
        <div className={classes.rootServices}>
          <Grid container
                direction="row"
                justify="center"
                alignItems="center">
            <Grid className={`${classes.itemService} ${classes.order1}  `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact className={classes.iconResposinve} iconSet={iconSet} color="#e30513"
                            size={100} icon="web"/>
              <div className={classes.itemTitleService}>
                Desarrollo Web
              </div>
              <div className={`${classes.marginService} hoverWhite`}>
                Creamos soluciones web a medida para optimizar los procesos de tu empresa, hacemos realidad tus ideas,
                enfocados en las necesidades de tu empresa.
              </div>
            </Grid>
            <Grid className={`${classes.itemServiceRed} ${classes.order2} `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact iconSet={iconSet} color="white" size={100} icon="movil"/>
              <div className={classes.itemTitleServiceWhite}>
                Desarrollo Movil

              </div>
              <div className={classes.marginService}>
                El mundo de las tablets y smarthphones esta en auge, desarrollamos apps hybridas (iOS y Android)
                cumpliendo tus necesidades y requerimientos.
              </div>
            </Grid>
            <Grid className={`${classes.itemServiceRed} ${classes.order3} `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact iconSet={iconSet} color="white" size={100} icon="data"/>
              <div className={classes.itemTitleServiceWhite}>
                Base de Datos
              </div>
              <div className={`${classes.marginService} hoverWhite`}>
                Sabemos que la información de tus aplicativos son muy importantes para ti, diseñamos y gestionamos
                bases de datos, analizamos y procesamos la información para ofrecerte reportes
                oportunos y reales.
              </div>
            </Grid>
            <Grid className={`${classes.itemService} ${classes.order4} `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact iconSet={iconSet} color="#e30513" size={100} icon="cloud"/>
              <div className={classes.itemTitleService}>
                Cloud Services
              </div>
              <div className={`${classes.marginService} hoverWhite`}>
                Olvídate de la infraestructura y enfócate en el crecimiento de tu empresa, tus aplicativos y tus bases
                de datos
                estarán disponibles el 100% del tiempo, en todo lugar usando nuestros servicios de la nube. GCP/AWS.
              </div>
            </Grid>

            <Grid className={`${classes.itemService} ${classes.order5} `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact iconSet={iconSet} color="#e30513" size={100} icon="web-page"/>
              <div className={classes.itemTitleService}>
                Paginas Web
              </div>
              <div className={`${classes.marginService} hoverWhite`}>
                Te ofrecemos un servicio personalizado y de calidad; plasmamos tu idea en tu página personal o
                corporativa.
              </div>
            </Grid>
            <Grid className={`${classes.itemServiceRed} ${classes.order6} `} item xs={12} sm={6} md={6} lg={6}>
              <IcomoonReact iconSet={iconSet} color="white" size={100} icon="hosting"/>
              <div className={classes.itemTitleServiceWhite}>
                Hosting/Dominios
              </div>
              <div className={classes.marginService}>
                Dominios y Alojamiento web que incluye certificado ssl gratuito, que garantiza confiabilidad y
                seguridad en su sitio.
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    )
  }
}


export default withStyles(styles)(UsSection)
