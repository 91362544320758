/**
 * App Reducers
 */
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import homeReducer from './home'

const reducers = combineReducers({
  home: homeReducer,
  form: formReducer
})

export default reducers