import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Drawer from '@material-ui/core/Drawer'
import Logo from '../../img/whiteLogo.png'
import { Link } from 'react-scroll'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      // display:'none'
    },
  },
  button: {
    margin: theme.spacing.unit,
    color: 'white',
  },
  appMenuContainer: {
    flex: 1,
    display: 'flex',
  },
  logoMenuContainer: {
    flexGrow: 8
  },
  itemsMenuContainer: {
    flexGrow: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      // display:'none'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  trasparent: {
    backgroundColor: '#ff00001f !important',
    boxShadow: 'none',
    transition:'all 1s ease-in',
  },
  noTrasparent: {
    backgroundColor: '#E30513',
    boxShadow: 'none',
    height: '56px',
    transition:'all 1s ease-in',
  },
  toolbar: {
    paddingLeft: '10%',
    paddingRight: '10%',

  },
  toolbarItem: {
    marginTop: '3px',
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      marginTop: '3px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      marginTop: '3px',
    },
    [theme.breakpoints.up('lg')]: {},
  },
  toolbarLinkItem: {
    cursor: "pointer",
    padding: "8px",
    fontWeight: "500",
    textTransform: "uppercase",
    boxSizing: "border-box",
    display: "inline-flex",
    verticalAlign: "middle",
    justifyContent: "center",
    marginTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    [theme.breakpoints.down('xs')]: {},
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      marginTop: '3px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      marginTop: '3px',
    },
    [theme.breakpoints.up('lg')]: {},
  },

  buttonMenuDrawer: {
    fontSize: '14px',
    color: '#ee2824'
  }
})

class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      left: false,
      isTop:true,

    }
  this.onScroll = this.onScroll.bind(this);
  this.handleSetActive = this.handleSetActive.bind(this);
  }


  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 200;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }
  handleSetActive(to) {
  }
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render () {
    const {classes} = this.props
    return (
      <div className={classes.root}>
        <AppBar style={{ backgroundColor: '#E30513',}} position="fixed" className={this.state.isTop ? classes.trasparent : classes.noTrasparent}>
          <Toolbar className={classes.toolbar}>
            {/*TODO DESCOMENTAR DAR ESTILOS ON MOBILE*/}
            <IconButton onClick={this.toggleDrawer('left', true)} className={classes.menuButton} color="inherit"
                        aria-label="Menu">
              <MenuIcon/>
            </IconButton>
            <div className={classes.appMenuContainer}>

              <div className={classes.logoMenuContainer}>
                <img width={150} src={Logo} alt="Logo"/>
              </div>
              <div className={classes.itemsMenuContainer}>

                <Link  className={`${classes.button} ${classes.toolbarLinkItem}`} activeClass="active" to="inicio" spy={true} smooth={true} offset={-5} duration={500} onSetActive={this.handleSetActive}>
                 Inicio
                </Link>
                <Link  className={`${classes.button} ${classes.toolbarLinkItem}`} activeClass="active" to="nosotros" spy={true} smooth={true} offset={-5} duration={500} onSetActive={this.handleSetActive}>
                  Nosotros
                </Link>
                <Link  className={`${classes.button} ${classes.toolbarLinkItem}`} activeClass="active" to="proyectos" spy={true} smooth={true} offset={-5} duration={500} onSetActive={this.handleSetActive}>
                  Proyectos
                </Link>
                <Link  className={`${classes.button} ${classes.toolbarLinkItem}`} activeClass="active" to="contactanos" spy={true} smooth={true} offset={-5} duration={500} onSetActive={this.handleSetActive}>
                  Contáctanos
                </Link>
                {/*<Link  className={`${classes.button} ${classes.toolbarLinkItem}`} activeClass="active" to="encuentranos" spy={true} smooth={true} offset={0} duration={500} onSetActive={this.handleSetActive}>*/}
                  {/*Encuentranos*/}
                {/*</Link>*/}
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            <MenuItem href="#nosotros"> <a href="#inicio" className={`${classes.button} ${classes.toolbarItem} ${classes.buttonMenuDrawer}`}>
              Inicio
            </a></MenuItem>
            <MenuItem onClick={this.handleClose}><a href="#nosotros"
                                                    className={`${classes.button} ${classes.toolbarItem} ${classes.buttonMenuDrawer}`}>
              Nosotros
            </a></MenuItem>

            <MenuItem onClick={this.handleClose}><a href="#proyectos"
                                                    className={`${classes.button} ${classes.toolbarItem} ${classes.buttonMenuDrawer}`}>
              Proyectos
            </a></MenuItem>
            <MenuItem onClick={this.handleClose}><a href="#contactanos"
                                                    className={`${classes.button} ${classes.toolbarItem} ${classes.buttonMenuDrawer}`}>
              Contáctanos
            </a></MenuItem>
          </div>
        </Drawer>
      </div>
    )
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MenuAppBar)
