import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import iconSet from '../../constants/selection.json'
import IcomoonReact from 'icomoon-react'
import { Element, Events, scroller, scrollSpy } from 'react-scroll'
import ContactForm from '../ContactForm'
import Footer from '../Footer'
import MainSection from '../MainSection'
import ProyectsSection from '../ProyectsSection'
import UsSection from '../UsSection'
import GoogleMapReact from 'google-map-react'
import { withSnackbar } from 'notistack'

const AnyReactComponent = ({text, classAny}) => <div className={classAny}>{text}</div>

const styles = theme => {
  return ({
    backGrounGrayWave: {
      backgroundSize: 'cover',
      // backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '400px',
      marginTop: '1px'
    },


    backGrounContactSection: {
      backgroundSize: 'cover',
      // backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      minHeight: '700px',
      height: 'auto',
      paddingTop: '5%',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        paddingTop: '50px'
      },
    },

    //  seccion 2,
    mapTeon: {
      minHeight: '500px',
      height: '500px',
      width: '100%',
      border: '1px solid #f7f7f7',
      padding: '15px',
      backgroundColor: 'white',
      WebkitBoxShadow: '0px 0px 24px -1px rgba(128, 128, 128, 0.59)',
      MozBoxShadow: '0px 0px 24px -1px rgba(128,128,128,1)',
      boxShadow: '0px 0px 24px -1px rgba(128, 128, 128, 0.64)'
    },
    pointerTeon: {
      width: '60px',
      height: '60px',
      backgroundColor: '#e30513',
      color: 'white',
      fontWeight: '700',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px'
    },
    itemIconMap: {
      padding: '20px',
    },
    itemIconText: {
      fontSize: '12px',
      color: 'gray',
      fontWeight: '500',
      paddingBottom: '40px'
    },
    paper: {
      borderRadius: 0,
    },
    itemContactTitle: {
      fontSize: '1.1rem',
      fontWeight: '600',
      textAlign: 'left'
    },
    itemContactTitleSecond: {
      fontSize: '1.1rem',
      fontWeight: '600',
      textAlign: 'left',
      marginTop: '1.5rem'
    },
    itemContactText: {
      textAlign: 'left',
      fontSize: '0.9rem',
      color: 'gray',
      paddingTop: '10px'
    },
    contentSectionMap: {
      padding: '0 12% 100px 12%',
      [theme.breakpoints.down('sm')]: {
        padding: '0 7% 100px 7%'
      },

    },
    contentSectionFlexMap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    itemInfoSectionMap: {
      flex: 1,
      marginRight: '-20%',
      zIndex: 1,
      [theme.breakpoints.down('xs')]: {
        marginRight: '0',
      },
    },
    itemMapSectionMap: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: '20px',
      },

    },
    socialNetwork:{
      textAlign:'left',
      '&> a':{
marginRight:'10px'
      }
    }

  })
}

function createMapOptions (maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
  }
}

class Page1 extends Component {
  handleNetworkRequest = () => {
    const {enqueueSnackbar} = this.props
    enqueueSnackbar('Successfully fetched the data.', {variant: 'warning'})
  }

  static defaultProps = {
    center: {
      lat: 0.344359,
      lng: -78.121305
    },
    zoom: 14
  }

  constructor (props) {
    super(props)
    this.state = {
      showAnimationCounter: false,
      emailRegister: null,
    }
    this.handleSetActiveCounter = this.handleSetActiveCounter.bind(this)
  }

  handleSetActiveCounter () {
    this.setState({showAnimationCounter: true})
  }

  componentDidMount () {
    let self = this
    document.addEventListener('scroll', () => {
      // console.log(scroller.getActiveLink(), 'page')
      if (scroller.getActiveLink() === 'proyectos' && !self.state.showAnimationCounter) {
        self.handleSetActiveCounter()
      }
    })
    scrollSpy.update()
    Events.scrollEvent.register('begin', function (to, element) {
    })
    Events.scrollEvent.register('end', function (to, element) {
      if (arguments[0] === 'proyectos' && !self.state.showAnimationCounter) {
        self.handleSetActiveCounter()
      }
    })
  }

  componentWillUnmount () {
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')
  }

  render () {

    const {classes} = this.props
    const imageUrlContact = require('../../img/bannerContactanos.png')
    return (
      <div>
        <Element>
          <MainSection/>
        </Element>
        <Element>
          <UsSection/>
        </Element>
        <Element>
          <ProyectsSection showAnimationCounter={this.state.showAnimationCounter}/>
        </Element>


        <Element id='contactanos' className={classes.backGrounContactSection} style={{
          backgroundImage: 'url(' + imageUrlContact + ')',
        }}>
          <h2 className={classes.titleSectionProyects}>Contáctanos</h2>

          <div className={classes.contentSectionMap}>
            <div className={classes.contentSectionFlexMap}>

              <div className={classes.itemInfoSectionMap}>
                <Paper style={{padding: '30px 20px'}}>

                  <div className={classes.root}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={4}>
                        <Paper className={classes.paper}>
                          <div className={classes.itemIconMap}>
                            <IcomoonReact iconSet={iconSet} color="#e30513" size={60} icon="contactus"/>
                          </div>
                          <div className={classes.itemIconText}>Nuestros Contactos</div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className={classes.paper}>
                          <div className={classes.itemContactTitle}>
                            Teléfono
                          </div>
                          <div className={classes.itemContactText}>
                            0984052409
                          </div>
                        </div>
                        <div className={classes.paper}>
                          <div className={classes.itemContactTitleSecond}>
                            Skype
                          </div>
                          <div className={classes.itemContactText}>
                            teontechsolutions
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <div className={classes.paper}>
                          <div className={classes.itemContactTitle}>
                            Email
                          </div>
                          <div className={classes.itemContactText}>
                            info@teon.ec
                          </div>
                        </div>
                        <div className={classes.paper}>
                          <div className={classes.itemContactTitleSecond}>
                            Síguenos

                          </div>
                            <div className={classes.socialNetwork}>
                              <a href={'https://www.facebook.com/TeonTechSolutions/'} target={'blank'}>
                              <IcomoonReact iconSet={iconSet} color="#e30513" size={18} icon="facebook"/>
                              </a>

                              <a href={'https://www.instagram.com/teontechsolutions/'} target={'blank'}>
                              <IcomoonReact iconSet={iconSet} color="#e30513" size={20} icon="instagram"/>
                              </a>
                            </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Paper className={classes.paper}>
                          <div className={classes.itemIconMap}>
                            <IcomoonReact iconSet={iconSet} color="#e30513" size={60} icon="ubication"/>
                          </div>
                          <div className={classes.itemIconText}>Nuestra Oficina</div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <div className={classes.paper}>
                          <div className={classes.itemContactTitle}>
                            Dirección
                          </div>
                          <div className={classes.itemContactText}>
                            Sánchez y Cifuentes 15-37 y Rafael Larrea
                          </div>
                        </div>
                      </Grid>

                    </Grid>
                  </div>

                </Paper>

              </div>
              <div className={classes.itemMapSectionMap}>
                <div className={classes.mapTeon}>
                  <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyD4llToOJZprBhHLwJrZToh9Zyg3eg60fM'}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    options={createMapOptions}
                  >
                    <AnyReactComponent
                      classAny={classes.pointerTeon}
                      lat={0.344359}
                      lng={-78.121305}
                      text={'TEON'}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>

          </div>
        </Element>
        <Element>
          <ContactForm/>
        </Element>
        <Element>
          <Footer/>
        </Element>
      </div>
    )
  }

}

Page1.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withSnackbar(Page1))

