import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { saveSuscriber } from '../../actions'
import {Copyright} from '@material-ui/icons'
import { withSnackbar } from 'notistack'

const styles = theme => ({
  backGroundFooter: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '500px',
    height: 'auto',
    paddingTop: '5%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      paddingTop: '50px',
      backgroundPosition: 'right'
    },
  },
  titleSectionFooter: {
    color: 'white',
    fontSize: '1.5rem',
    paddingTop: '150px',
    paddingBottom: '10px',
    textTransform: 'UPPERCASE',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px'
    },

  },
  decriptionSectionFooter: {
    color: 'white',
    fontSize: '0.8rem',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      fontSize: '1rem',
    },

  },
  inputSuscribe: {
    'borderRadius': '6px 0px 0px 6px',
    'border': '1px solid #ffffff94',
    'backgroundColor': '#dd0f0f00',
    'borderRight': 'none',
    color: 'white',
    '&::placeholder': {
      color: 'white',
      fontSize: '1rem',
      paddingLeft: '20px',
    }
  },
  btnSuscribe: {
    'borderRadius': '0px 10px 10px 0px',
    'position': 'absolute',
    'border': '0',
    minHeight: '44px',
    'marginLeft': '0px',
    'color': '#dd0f0f',
    'background': 'white',
    'fontSize': '16px',
    'minWidth': '110px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#a0a0a0',
      color: 'white',
      // 'fontSize': '18px',
      transition: 'all 0.s ease 0s',
    }
  },
  formSuscribe: {
    marginRight: '120px'
  },
  footerCopyRight:{
    "marginTop": "100px",
    "fontSize": "1rem",
    "color": "white",
    "verticalAlign": "bottom",
    "fontWeight": "500",
    "justifyContent": "flex-end !important",
    paddingBottom:'30px',
  }
})
const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const emailSuscribe = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email Invalido'
    : undefined
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Numero de teléfono, debe tener 10 dígitos'
    : undefined

const renderField = ({input, label, inputAdorment, type, meta: {touched, error, warning}}) => (
  <div className='inputFormSuscribeTeon'>

    <input
      className={'inputSuscribe'}
      placeholder={label}
      type="text"
      {...input}
    />
    {touched &&
    ((error && <div className={'errorFormTeon'}>{error}</div>) ||
      (warning && <div className={'errorFormTeon'}>{warning}</div>))}
  </div>
)

class Footer extends React.Component {
  constructor (props){
    super(props)
  }
  handleSubmit = (values) => {
    const {enqueueSnackbar} = this.props
    this.props.saveSuscriber(Object.assign({}, values, {date: new Date()}), this.props.reset,enqueueSnackbar)
  }

  render () {
    const {classes, handleSubmit, submitting} = this.props
    const imageFooter = require('../../img/footerV2.png')
    return (
      <div className={classes.backGroundFooter} style={{
        backgroundImage: 'url(' + imageFooter + ')',
      }}>

        <h3 className={classes.titleSectionFooter}>Registrate para recibir noticias</h3>
        <div className={classes.decriptionSectionFooter}>
          Te enviaremos notificaciones acerca de nuestros servicios y descuentos especiales.
        </div>
        <div>
          <form style={{marginRight: '120px'}} onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              name="email"
              validate={[required, emailSuscribe]}
              component={renderField}
              label="Escribe tu email"
            />
            <input className={classes.btnSuscribe} type={'submit'} disabled={submitting} variant="contained"
                   value="Suscribirse"/>
          </form>
        </div>
        <div className={classes.footerCopyRight}>Copyright <Copyright/> 2020 TEON</div>
      </div>
    )

  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const maStateToProps = ({home}) => {
  return {home}
}

const mapDispatchToProps = {
  saveSuscriber
}

Footer = connect(
  maStateToProps,
  mapDispatchToProps
)(Footer)

export default withStyles(styles)(
  reduxForm({
    form: 'suscriberForm',
  })(withSnackbar(Footer))
)
