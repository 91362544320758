import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CountUp from 'react-countup'

const styles = theme => {
  return ({
      rootServices: {
        flexGrow: 1,
      },
      backGrounLigthGrayWave: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
          paddingTop: '100px'
        },
      },
      titleSectionProyects: {
        color: '#e30513',
        paddingTop: '100px',
        paddingBottom: '50px',
        textTransform: 'UPPERCASE',
        [theme.breakpoints.down('xs')]: {
          paddingTop: '50px'
        },

      },
      itemCounter: {
        minHeight: '185px',
        padding: '30px',
        borderRadius: '0',
        '&:hover hr': {
          width: '60%'
        },
      },

      descrptionItemProyect: {
        fontSize: '14px',
        color: '#6F6F6E',
      },
      accountBalance: {
        fontSize: '2rem',
        fontWeight: '700'
      },
      dividerTeonProyect: {
        width: '40%',
        borderTop: '2px solid #E30513',
        transition: 'width 1s',
      },

    }
  )
}

class ProyectsSection extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const onComplete = () => {
    }

    const onStart = () => {
      console.log('Started! 💨')
    }
    const {classes, showAnimationCounter} = this.props
    const imageUrlProyects = require('../../img/bannerRedV2.png')
    return (
      <div id='proyectos' className={classes.backGrounLigthGrayWave} style={{
        backgroundImage: 'url(' + imageUrlProyects + ')',
      }}>
        <div style={{padding: '50px'}}>
          <div className={classes.rootServices}>
            <Grid container direction="row"
                  justify="center"
                  alignItems="center" spacing={24}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Paper className={`${classes.paper} ${classes.itemCounter}  `}>
                  {showAnimationCounter &&
                  (
                    <CountUp
                      className={classes.accountBalance}
                      start={0}
                      end={48}
                      duration={2.75}
                      useEasing={true}
                      useGrouping={true}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix=""
                      onComplete={onComplete}
                      onStart={onStart}
                    />

                  )
                  }
                  <div>
                    <hr className={classes.dividerTeonProyect}/>
                  </div>
                  <div className={classes.descrptionItemProyect}>
                    Proyectos Desarrollados
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Paper className={`${classes.paper} ${classes.itemCounter}  `}>
                  {showAnimationCounter &&

                  (
                    <CountUp
                      className={classes.accountBalance}
                      start={0}
                      end={34568}
                      duration={2.75}
                      useEasing={true}
                      useGrouping={true}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix=""
                      onComplete={onComplete}
                      onStart={onStart}
                    />
                  )
                  }
                  <div>
                    <hr className={classes.dividerTeonProyect}/>
                  </div>
                  <div className={classes.descrptionItemProyect}>
                    Líneas de Código
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Paper className={`${classes.paper} ${classes.itemCounter}  `}>
                  {showAnimationCounter &&

                  (
                    <CountUp
                      className={classes.accountBalance}
                      start={0}
                      end={26}
                      duration={2.75}
                      useEasing={true}
                      useGrouping={true}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix=""
                      onComplete={onComplete}
                      onStart={onStart}
                    />

                  )
                  }
                  <div>
                    <hr className={classes.dividerTeonProyect}/>
                  </div>
                  <div className={classes.descrptionItemProyect}>
                    Clientes Satisfechos
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Paper className={`${classes.paper} ${classes.itemCounter}  `}>
                  {showAnimationCounter &&

                  (
                    <CountUp
                      className={classes.accountBalance}
                      start={0}
                      end={48}
                      duration={2.75}
                      useEasing={true}
                      useGrouping={true}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix=""
                      onComplete={onComplete}
                      onStart={onStart}
                    />
                  )
                  }
                  <div>
                    <hr className={classes.dividerTeonProyect}/>
                  </div>
                  <div className={classes.descrptionItemProyect}>
                    Proyectos Desarrollados
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ProyectsSection)
