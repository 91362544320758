import "./firebase";
import React, { Component } from "react";
import { Provider } from "react-redux";
import "./App.css";
import AppContainer from "./containers/App";
import { configureStore } from "./store";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Christmas from "./components/Page2";

class App extends Component {
  render() {
    return (
      <Provider store={configureStore()}>
        <BrowserRouter>
          <Switch>
            <Route path={"/merry-christmas/:name/:languaje?"} component={Christmas} />
            <Route path={"/"} component={AppContainer} />
            <Redirect exact to={`/`} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
