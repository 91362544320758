import React, { Component } from 'react'
import Phone from '../../img/teonPhoneLigth.png'
import { withStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow'

const styles = theme => {
  return ({
    imgResponsive: {
      width: '70%',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      },
      [theme.breakpoints.up('md')]: {
        width: '90%'
      },
      [theme.breakpoints.up('lg')]: {
        width: '70%'
      },
    },
    backGrounWave: {
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '99vh',
    },
    mainDescription: {
      color: 'white',
      textAlign: 'left',
      paddingLeft: '20%',
      fontWeight: 'bolder',
      [theme.breakpoints.down('xs')]: {
        // display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        // width: '80%'
        paddingLeft: '0%',
        fontSize: '2rem',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '13%',
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '20%'
      },
    },
    mainContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: '0px 10%'
    },
    mainContentPhone: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        flex: 0,
        // display: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        // width: '80%'
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%',
      },

    },
  })
}

class MainSection extends Component {
  render(){

    const {classes} = this.props
    const imageUrl = require('../../img/ondasV2.png')
    return(
      <div id={'inicio'} className={classes.backGrounWave}
               style={{
                 backgroundImage: 'url(' + imageUrl + ')',
               }}>

        <div className={classes.mainContent}>
          <div style={{flex: 1}}>
            <div style={{}}>
              <h1 className={classes.mainDescription}>
                DESARROLLAMOS SOLUCIONES TECNOLÓGICAS A MEDIDA.
              </h1>
            </div>


          </div>
          <Grow in={true} timeout={1000}>
            <div className={classes.mainContentPhone}>
              <img className={classes.imgResponsive} src={Phone} alt="Logo"/>
            </div>
          </Grow>
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(MainSection)
