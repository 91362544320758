import axios from 'axios'

const apiInstance = axios.create({
  baseURL: 'https://reqres.in/api',
  timeout: 10000
})
/**
 * requests for api users
 */
export const getUsers = async () =>
  await apiInstance.get(`/users`)
    .then(response => response.data)
    .catch(error => error)
