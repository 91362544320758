import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  msjChristmas: {
    fontSize: "16px",
    fontWeight: "bold",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "80%"
    }
  },
  headerContainer:{
    "width": "100%",
    "display": "inline-block",
  },
  divHeader1:{
      padding:'40px',
    display:'inline-block',
    [theme.breakpoints.down("sm")]: {
        width: "70%"
      }
  },
  divHeader2:{
    padding:'20px',
    display:'inline-block',
    float:'right',
    width:'100px',
    [theme.breakpoints.down("sm")]: {
        width: "40px",
        padding: '20px 4px'
      }
  },
  treeItem:{
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100px",
      }
  },
  contentStyle: {
    fontSize: "35px",
    fontWeight: "bold",
    fontFamily: "Lato",
    [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
      }
  },
  mainMsj:{
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding:'10% 20%'
      }
  },
  mainMsj2:{
    width: "60%",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
      }
  },
  containerItem:{
    width: "100%",
    height: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        height: "100px",
        order:1,
        'img':{
           
        }
      }
  }
}));

export default styles;
