import firebase from 'firebase'
import 'firebase/firestore'
// Initialize Firebase
firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    authDomain: process.env.REACT_APP_FIREBASE_authDomain,
    databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
    projectId: process.env.REACT_APP_FIREBASE_projectId,
    storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId
  }
)

const db = firebase.firestore()
const settings = {/* your settings... */ timestampsInSnapshots: true}
db.settings(settings)
export {
  db
}
