import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { saveContact } from '../../actions'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Person, Email, PhoneAndroid} from '@material-ui/icons'
import { withSnackbar } from 'notistack'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '40px '
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: 40,
    width:'90%',
    margin:'0px 10px'
  },
  button:{
    width: "97%",
    borderRadius: "0",
    boxShadow: "none",
    backgroundColor: "#e30513",
    color: "white"
  },
})

const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Email Invalido'
    : undefined
const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Solo letras y números'
    : undefined
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Numero de teléfono, debe tener 10 dígitos'
    : undefined

const renderField = ({input, label, inputAdorment,classNames, type, meta: {touched, error, warning}}) => (
  <div className='inputFormTeon'>
      <TextField
        label={label}
        className={classNames}
        {...input}
        InputProps={{
          endAdornment: inputAdorment,
        }}
      />
      {touched &&
      ((error && <div className={'errorFormTeon'}>{error}</div>) ||
        (warning && <div className={'errorFormTeon'}>{warning}</div>))}
  </div>
)
const renderFieldTextarea = ({input, label, type, meta: {touched, error, warning}}) => (
  <div>
    <textarea style={{width:'97%',padding:'20px'}} rows={5} {...input} placeholder={label}/>
    {touched &&
    ((error && <div className={'errorFormTeon'}>{error}</div>) ||
      (warning && <div className={'errorFormTeon'}>{warning}</div>))}
  </div>
)

class ContactForm extends React.Component {
  constructor (props){
    super(props)
  }
  handleSubmit = (values) => {
    const {enqueueSnackbar} = this.props
    this.props.saveContact(values, this.props.reset, enqueueSnackbar)
  }

  render () {
    const {classes, handleSubmit, submitting} = this.props
    return (
      <div style={{padding: '80px 15%'}} className={classes.root}>
        <form style={{flex:1,display:'flex'}} onSubmit={handleSubmit(this.handleSubmit)}>
          <Grid container direction="row"
                justify="center"
                alignItems="center">
            <Grid item xs={12} sm={4}>
                <Field
                  name="name"
                  type="text"
                  component={renderField}
                  classNames={(classes.margin, classes.textField)}
                  label="Nombre"
                  validate={[required]}
                  warn={alphaNumeric}
                  inputAdorment={<InputAdornment position="end">
                    <Email color="disabled" />
                  </InputAdornment>}

                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Field
                  name="email"
                  type="email"
                  classNames={(classes.margin, classes.textField)}
                  component={renderField}
                  label="Correo"
                  validate={[required, email]}
                  inputAdorment={<InputAdornment position="end">
                    <Person color="disabled" />
                  </InputAdornment>}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Field
                  name="phone"
                  type="text"
                  classNames={(classes.margin, classes.textField)}
                  component={renderField}
                  label="Teléfono"
                  validate={[required]}
                  inputAdorment={<InputAdornment position="end">
                    <PhoneAndroid color="disabled"/>
                  </InputAdornment>}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                  name="message"
                  validate={[required]}
                  component={renderFieldTextarea}
                  label="Escribe tu mensaje"
                />
            </Grid>
            <Grid item xs={12}>
              <Button type={'submit'} disabled={submitting} variant="contained" className={classes.button}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({home}) => {
  return {home}

}

const mapDispatchToProps = {
  saveContact
}

ContactForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm)
export default withStyles(styles)(reduxForm({
  form: 'loginForm',
})(withSnackbar(ContactForm)))
