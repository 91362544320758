import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
// api
import * as api from '../api'
import { db } from '../firebase'

import { GET_USERS, SAVE_CONTACT, SAVE_SUSCRIBER } from '../actions/types'

import {
  getUsersFailure,
  getUsersSuccess,
  saveContactFailure,
  saveContactSuccess,
  saveSuscriberFailure,
  saveSuscriberSuccess
} from '../actions/home'

function * getUsersFromServer () {
  try {
    const response = yield call(api.getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFailure(error))
  }
}

export function * getUsers () {
  yield takeEvery(GET_USERS, getUsersFromServer)
}

function * saveContactInFirebase ({payload}) {
  let {data, resetFunction, notifyFunction} = payload
  try {
    const response = yield db.collection('contacts').add(data)
    yield put(saveContactSuccess(response))
    resetFunction()
    notifyFunction('Información enviada correctamente',{ variant: 'success' })
  } catch (error) {
    notifyFunction('Error al enviar la Información!',{ variant: 'error' })
    yield put(saveContactFailure(error))
  }
}

function * saveSuscriberInFirebase ({payload}) {
    let {data, resetFunction,notifyFunction} = payload
  try {
    const response = yield db.collection('suscribers').add(data)
    yield put(saveSuscriberSuccess(response))
    resetFunction()
    notifyFunction('Te has Suscrito Correctamente!',{ variant: 'success' })
  } catch (error) {
    yield put(saveSuscriberFailure(error))
    notifyFunction('Error al enviar la Información!',{ variant: 'error' })
  }
}

export function * saveContact () {
  yield takeEvery(SAVE_CONTACT, saveContactInFirebase)
}

export function * saveSuscriber () {
  yield takeEvery(SAVE_SUSCRIBER, saveSuscriberInFirebase)
}

/**
 * Root Saga
 */
export default function * rootSaga () {
  yield all([
    fork(getUsers),
    fork(saveContact),
    fork(saveSuscriber)
  ])
}
